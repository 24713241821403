import programboys23 from "../imgs/teams/23-boys.jpg";
import programboys24 from "../imgs/teams/24-boys.jpg";
import programboys25 from "../imgs/teams/25-boys.JPG";
import programgirls23 from "../imgs/teams/23-girls.jpg";
import programgirls24 from "../imgs/teams/24-girls.jpg";

export const ProgramList = [
	{ season: "Boys", year: 2023, img: programboys23 },
	{ season: "Girls", year: 2023, img: programgirls23 },
	{ season: "Boys", year: 2024, img: programboys24 },
	{ season: "Girls", year: 2024, img: programgirls24 },
	{ season: "Boys", year: 2025, img: programboys25 },
];
