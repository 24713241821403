import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Awards from "./awards/Awards";
import Coaches from "./coaches/Coaches";
import Grass from "./grass/Grass";
import GrassAdmin from "./grass/GrassAdmin";
import Home from "./home/Home";
import Records from "./records/Records";
import Schedule from "./schedule/Schedule";
import Teams from "./teams/Teams";
import { useEffect } from "react";
import CalendarView from "./calendar/Calendar";
import RecordCard from "./records/RecordCard";

function App() {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = "https://w.behold.so/widget.js";
		script.type = "module";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/coaches" element={<Coaches />} />
					<Route exact path="/schedule" element={<Schedule />} />
					<Route exact path="/watch" element={<Schedule />} />
					<Route exact path="/calendar" element={<CalendarView />} />
					<Route exact path="/teams" element={<Teams />} />
					<Route exact path="/records" element={<Records />} />
					<Route exact path="/recordcard" element={<RecordCard />} />
					<Route exact path="/grassadmin" element={<GrassAdmin />} />
					<Route exact path="/grass" element={<Grass />} />
					<Route exact path="/awards" element={<Awards />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
