import "./Home.css";
import "../App.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import Insta from "../modules/Insta";
import { SWLink } from "../modules/SWLink";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import webvid from "../imgs/web.mp4";
import tryouts from "../tryouts.pdf";

function Home() {
	const location = useLocation();
	useEffect(() => {
		if (window.beholdWidgets !== undefined) {
			window.beholdWidgets.initialize();
		}
	}, [location]);
	return (
		<div className="App">
			<Header active={"Home"} />
			<div className="content">
				<div className="card videoContainer">
					<video
						loop
						muted
						autoPlay
						playsInline
						controls={false}
						src={webvid}
					/>
					<div className="titleArea caption">
						<h2 className="">Foothills Volleyball</h2>
						<h5>Boy's season has kicked off!</h5>
					</div>
				</div>

				{/* <div className="card hero31bg">
					<div className="titleArea" style={{ aspectRatio: "3/1" }}>
						<h3>Boy's Tryouts</h3>
					</div>
					<div className="bottomText">
						<div style={{ textAlign: "center" }}>
							<h4>Tryouts run Feb 10 - Feb 14</h4>
							<small>
								Read our{" "}
								<a href={tryouts} target="_blank" rel="noreferrer">
									Tryout PDF
								</a>{" "}
								for more info
							</small>
						</div>
					</div>
				</div> */}

				<div className="instagram">
					<figure data-behold-id="9LZtFnmsA4ATfJkq9l3D"></figure>
				</div>

				<div className="card grass">
					<div className="titleArea">
						<h3>Grass League</h3>
					</div>
					<div className="bottomText">
						<div>
							<p>
								<h4>Grass League 2025 is complete!</h4>
							</p>
							<p>2026 grass starts in December!</p>
							<h5>Winners:</h5>
							<p>2025 - Kyle Senkerik</p>
							<p>2024 - Logan Freas</p>
							<p>2023 - London Dyjak</p>
						</div>
					</div>
				</div>

				<div className="card watchlive">
					<div className="titleArea">
						<h3>Watch Live Matches!</h3>
					</div>
					<div className="bottomText">
						<div>
							<p>
								<strong>
									Live Streaming is provided by StatTogether.com and is
									completely free to watch!
								</strong>{" "}
							</p>
							<p>
								You can watch matches on the{" "}
								<a href="/schedule">Schedule Page</a> or on our{" "}
								<a
									href="https://www.youtube.com/channel/UCuxebFzWzUX6SZEO9rpE8Jg"
									target="_blank"
									rel="noreferrer"
								>
									YouTube Channel.
								</a>{" "}
							</p>
							<p>
								Use the YouTube app on your phone to subscribe to our{" "}
								<a
									href="https://www.youtube.com/channel/UCuxebFzWzUX6SZEO9rpE8Jg"
									target="_blank"
									rel="noreferrer"
								>
									YouTube channel
								</a>{" "}
								and tap the bell icon to get notifications when matches start.
							</p>
						</div>
					</div>
				</div>

				<div className="card logobg">
					<div className="titleArea">
						<h3>Donations</h3>
					</div>
					<div className="bottomText" style={{ padding: "0 32px" }}>
						<div>
							<p>
								<strong>
									Your donations help our athletes succeed! Please consider
									helping us by donating to our booster club directly or by
									purchasing one of these items outright.
								</strong>
								<br />
								<em>Listed in order of importance to our program:</em>
							</p>
							<ol>
								<li>
									<a
										href="https://www.realvolleyball.com/volleyballs/molten-v58l-protouch-volleyball-red-white-blue/"
										target="_blank"
										rel="noreferrer"
									>
										Pro Touch Volleyballs (Boys) ($49)
									</a>{" "}
									- We need ~30 more!
								</li>
								<li>
									<a
										href="https://www.realvolleyball.com/volleyballs/molten-iv58l-super-touch-volleyball-black-white-blue/"
										target="_blank"
										rel="noreferrer"
									>
										Super Touch Volleyballs (Girls) ($49)
									</a>{" "}
									- We need ~40 more!
								</li>
								<li>
									<a
										href="https://www.amazon.com/dp/B0002C0NEO?ref=ppx_yo2ov_dt_b_fed_asin_title&th=1&psc=1"
										target="_blank"
										rel="noreferrer"
									>
										Court Tape (2" x 60 yd) ($11)
									</a>{" "}
									- We use 360 yards (6 rolls) to fully tape down all the court
									lines in our gym.
								</li>
								<li>
									<a
										href="https://www.anthem-sports.com/tandem-sport-tstarget-volleyball-target-challenger.html"
										target="_blank"
										rel="noreferrer"
									>
										Volleyball Target ($435)
									</a>
								</li>
								<li>
									<a
										href="https://www.amazon.com/dp/B0834W5L3L"
										target="_blank"
										rel="noreferrer"
									>
										3D Print Filament (Falcon Silver) ($15)
									</a>{" "}
									- To improve our TV Cart and other projects
								</li>
								<li>
									<a
										href="https://www.amazon.com/dp/B0CZRK4Y52"
										target="_blank"
										rel="noreferrer"
									>
										3D Print Filament (Falcon Blue) ($15)
									</a>{" "}
									- To improve our TV Cart and other projects
								</li>
								<li>
									<a
										href="https://sportsattack.com/product/volleyball-machine-cover/"
										target="_blank"
										rel="noreferrer"
									>
										Serve Machine Cover ($229)
									</a>{" "}
									- To protect our serving machine from P.E. classes
								</li>
								<li>
									<del>Volleyball Target ($435)</del>{" "}
									<strong>Donated by the Brown family</strong>
								</li>
								<li>
									<del>Serving Machine ($4,800)</del>{" "}
									<strong>Purchased through fundraising</strong>
								</li>
								<li>
									<del>Drill Cart ($169)</del>{" "}
									<strong>Donated by the Brown family</strong>
								</li>
								<li>
									<del>Practice/Film TV ($289)</del>{" "}
									<strong>Purchased thanks to parent donations</strong>
								</li>
								<li>
									<del>Antennas</del>{" "}
									<strong>
										Donated by{" "}
										<a
											href="https://sidewinderva.com"
											target="_blank"
											rel="noreferrer"
										>
											Sidewinder Volleyball Academy
										</a>
									</strong>
								</li>
								<li>
									<del>New pair of poles ($5,000)</del>{" "}
									<strong>Purchased by CFSD</strong>
								</li>
								<li>
									<del>Custom Pole Pads</del>{" "}
									<strong>Purchased through fundraising</strong>
								</li>
								<li>
									<del>Big Cart ($299)</del>{" "}
									<strong>
										Donated by{" "}
										<a
											href="https://sidewinderva.com"
											target="_blank"
											rel="noreferrer"
										>
											Sidewinder Volleyball Academy
										</a>
									</strong>
								</li>
								<li>
									<del>Drill Cart</del>{" "}
									<strong>Donated by the Deconcini family</strong>
								</li>
								<li>
									<del>Pocket Radar Gun</del>{" "}
									<strong>Purchased thanks to parent donations</strong>
								</li>
								<li>
									<del>Mobile Whiteboard</del>{" "}
									<strong>Donated by the Terpning family</strong>
								</li>
								<li>
									<del>Setter's Target</del>{" "}
									<strong>
										Donated by{" "}
										<a
											href="https://sidewinderva.com"
											target="_blank"
											rel="noreferrer"
										>
											Sidewinder Volleyball Academy
										</a>
									</strong>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Home;
