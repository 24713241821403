import jvaboys22 from "../imgs/teams/22-boys-jv.jpg";
import jvaboys23 from "../imgs/teams/23-boys-jva.jpg";
import jvaboys24 from "../imgs/teams/24-boys-jva.jpg";
import jvagirls22 from "../imgs/teams/22-girls-jva.jpeg";
import jvagirls23 from "../imgs/teams/23-girls-jva.jpg";
import jvagirls24 from "../imgs/teams/24-girls-jva.jpg";
import jvbboys23 from "../imgs/teams/23-boys-jvb.jpg";
import jvbboys24 from "../imgs/teams/24-boys-jvb.jpg";
import jvbgirls22 from "../imgs/teams/22-girls-jvb.jpeg";
import jvbgirls23 from "../imgs/teams/23-girls-jvb.jpg";
import jvbgirls24 from "../imgs/teams/24-girls-jvb.jpg";
import varsityboys22 from "../imgs/teams/22-boys-v.jpg";
import varsityboys23 from "../imgs/teams/23-boys-v.jpg";
import varsityboys24 from "../imgs/teams/24-boys-v.jpg";
import varsitygirls22 from "../imgs/teams/22-girls-v.jpeg";
import varsitygirls23 from "../imgs/teams/23-girls-v.jpg";
import varsitygirls24 from "../imgs/teams/24-girls-v.jpg";
import jvbboys25 from "../imgs/teams/25-boys-jvb.JPG";
import jvaboys25 from "../imgs/teams/25-boys-jva.JPG";
import varsityboys25 from "../imgs/teams/25-boys-v.JPG";

export const TeamList = [
	// Boys V 25
	{
		season: "Boys",
		year: 2025,
		level: "Varsity",
		value: "SA6GF1TL",
		img: varsityboys25,
		coaches: ["Andrew Adair", "Kyle Wells"],
		roster: [
			{ number: 1, name: "Zane Schwartz", class: "Senior" },
			{ number: 3, name: "Jayden Scholz", class: "Senior" },
			{ number: 4, name: "Kyle Senkerik", class: "Senior" },
			{ number: 2, name: "Isak Jaime", class: "Senior" },
			{ number: 11, name: "Dylan Rubio", class: "Senior" },
			{ number: 12, name: "Jacob Martinez", class: "Senior" },
			{ number: 6, name: "Quinten Kiracofe", class: "Senior" },
			{ number: 5, name: "Thomas Vamos", class: "Junior" },
			{ number: 7, name: "Connor Daggett", class: "Junior" },
			{ number: 8, name: "Jayce Rooney", class: "Junior" },
			{ number: 9, name: "Gregan Vamos", class: "Sophomore" },
			{ number: 10, name: "Julian Merheb", class: "Sophomore" },
			{ number: 13, name: "Vincent Merheb", class: "Freshman" },
		],
		active: true,
	},
	// Boys JVA 25
	{
		season: "Boys",
		year: 2025,
		level: "JV-A",
		value: "94DPFSGP",
		img: jvaboys25,
		coaches: ["Franklin Donley"],
		roster: [
			{ number: 4, name: "Connor Doty", class: "Junior" },
			{ number: 7, name: "Samson Goldberg", class: "Junior" },
			{ number: 3, name: "Sutton Heisey", class: "Junior" },
			{ number: 15, name: "Will Luxbacher", class: "Junior" },
			{ number: 11, name: "Sam Savin", class: "Junior" },
			{ number: 6, name: "Ariston Elsesser", class: "Sophomore" },
			{ number: 14, name: "Malcolm Frank", class: "Sophomore" },
			{ number: 10, name: "Jameson Leary", class: "Sophomore" },
			{ number: 8, name: "Santiago Morimoto", class: "Sophomore" },
			{ number: 5, name: "Bryce Schwartz", class: "Sophomore" },
			{ number: 13, name: "Cuyler Zeh Plunkett", class: "Sophomore" },
			{ number: 12, name: "Theodore LeBlanc", class: "Freshman" },
			{ number: 2, name: "Vaughn Rooney", class: "Freshman" },
		],
		active: true,
	},
	// Boys JVB 25
	{
		season: "Boys",
		year: 2025,
		level: "JV-B",
		value: "P673VCU6",
		img: jvbboys25,
		coaches: ["Julissa Serna"],
		roster: [
			{ number: 1, name: "Dakota Gossmiller", class: "Sophomore" },
			{ number: 5, name: "Fin Keller", class: "Sophomore" },
			{ number: 14, name: "Peter Greer", class: "Freshman" },
			{ number: 7, name: "Ashton Le", class: "Freshman" },
			{ number: 15, name: "Evan Morales", class: "Freshman" },
			{ number: 9, name: "Jeremiah Rouse", class: "Freshman" },
			{ number: 8, name: "Leo Spaulding", class: "Freshman" },
			{ number: 2, name: "Vaughn Rooney", class: "Freshman" },
			{ number: 11, name: "Theodore LeBlanc", class: "Freshman" },
		],
		active: true,
	},

	// Boys V 24
	{
		season: "Boys",
		year: 2024,
		level: "Varsity",
		value: "L8VZ29C4",
		img: varsityboys24,
		coaches: ["Andrew Adair", "Kyle Wells"],
		roster: [
			{ number: 1, name: "Zane Schwartz", class: "Junior" },
			{ number: 3, name: "Jayden Scholz", class: "Junior" },
			{ number: 4, name: "Kyle Senkerik", class: "Junior" },
			{ number: 5, name: "Isak Sanchez", class: "Junior" },
			{ number: 6, name: "Chase Scheffman", class: "Senior" },
			{ number: 7, name: "Torin Claggett", class: "Senior" },
			{ number: 8, name: "Wilson Spaulding", class: "Senior" },
			{ number: 9, name: "Logan Freas", class: "Senior" },
			{ number: 10, name: "Josh Eisenberg", class: "Senior" },
			{ number: 11, name: "Dylan Rubio", class: "Junior" },
			{ number: 12, name: "Jacob Martinez", class: "Junior" },
			{ number: 13, name: "Zak Gruber", class: "Senior" },
		],
		active: true,
	},
	// Boys JVA 24
	{
		season: "Boys",
		year: 2024,
		level: "JV-A",
		value: "ZV799SXS",
		img: jvaboys24,
		coaches: ["Shane McAdams"],
		roster: [
			{ number: 1, name: "Aidan Fuller", class: "Sophomore" },
			{ number: 2, name: "Brody Green", class: "Freshman" },
			{ number: 3, name: "Sutton Heisey", class: "Sophomore" },
			{ number: 4, name: "Will Luxbacher", class: "Sophomore" },
			{ number: 5, name: "Sam Savin", class: "Sophomore" },
			{ number: 6, name: "Ariston Elsesser", class: "Freshman" },
			{ number: 7, name: "Thomas Vamos", class: "Sophomore" },
			{ number: 8, name: "Jayce Rooney", class: "Sophomore" },
			{ number: 9, name: "Samson Goldberg", class: "Sophomore" },
			{ number: 10, name: "Julian Merheb", class: "Freshman" },
			{ number: 11, name: "Connor Daggett", class: "Sophomore" },
			{ number: 12, name: "Killian Hickey", class: "Sophomore" },
			{ number: 13, name: "Elijah Cohen", class: "Sophomore" },
		],
		active: true,
	},
	// Boys JVB 24
	{
		season: "Boys",
		year: 2024,
		level: "JV-B",
		value: "EWC8U4GX",
		img: jvbboys24,
		coaches: ["Jules Serna"],
		roster: [
			{ number: 1, name: "Bryce Schwartz", class: "Freshman" },
			{ number: 2, name: "Conrad Cabrera", class: "Freshman" },
			{ number: 3, name: "Gavin Hoose", class: "Freshman" },
			{ number: 4, name: "Gregan Vamos", class: "Freshman" },
			{ number: 5, name: "Bray Koepke", class: "Freshman" },
			{ number: 6, name: "Diego Galina Garcia", class: "Freshman" },
			{ number: 7, name: "Jameson Leary", class: "Freshman" },
			{ number: 8, name: "Fin Keller", class: "Freshman" },
			{ number: 9, name: "Cuyler Zeh Plunkett", class: "Freshman" },
			{ number: 10, name: "Javier Sanchez", class: "Freshman" },
			{ number: 11, name: "Cameron Von Borstel", class: "Freshman" },
			{ number: 12, name: "Bergen Frisch", class: "Freshman" },
			{ number: 14, name: "Malcolm Frank", class: "Freshman" },
		],
		active: true,
	},
	// Boys V 23
	{
		season: "Boys",
		year: 2023,
		level: "Varsity",
		value: "DAF9RL9R",
		img: varsityboys23,
		coaches: ["Andrew Adair"],
		roster: [
			{ number: 1, name: "Zane Schwartz", class: "Sophomore" },
			{ number: 3, name: "Logan Freas", class: "Junior" },
			{ number: 4, name: "Kyle Senkerik", class: "Sophomore" },
			{ number: 5, name: "Tyler DeConcini", class: "Senior" },
			{ number: 6, name: "Quinten Kiracofe", class: "Sophomore" },
			{ number: 7, name: "Kyle Boesen", class: "Senior" },
			{ number: 8, name: "London Dyjak", class: "Senior" },
			{ number: 9, name: "Zak Gruber", class: "Junior" },
			{ number: 10, name: "Josh Eisenberg", class: "Junior" },
			{ number: 11, name: "Cole Revis", class: "Senior" },
			{ number: 12, name: "Jacob Martinez", class: "Sophomore" },
			{ number: 13, name: "Wilson Spaulding", class: "Junior" },
			{ number: 15, name: "Dylan Rubio", class: "Sophomore" },
		],
		active: true,
	},
	// Boys JVA 23
	{
		season: "Boys",
		year: 2023,
		level: "JV-A",
		value: "B7MDA6AM",
		img: jvaboys23,
		coaches: ["Kyle Wells"],
		roster: [
			{ number: 1, name: "Aidan Fuller", class: "Freshman" },
			{ number: 3, name: "Zane Schwartz", class: "Sophomore" },
			{ number: 4, name: "Jayden Scholz", class: "Sophomore" },
			{ number: 5, name: "Nathan Adler", class: "Sophomore" },
			{ number: 6, name: "Quinten Kiracofe", class: "Sophomore" },
			{ number: 7, name: "Torin Claggett", class: "Junior" },
			{ number: 8, name: "Josh Eisenberg", class: "Junior" },
			{ number: 10, name: "Kyle Senkerik", class: "Sophomore" },
			{ number: 11, name: "Aiden Messenger", class: "Sophomore" },
			{ number: 12, name: "Isak Sanchez", class: "Sophomore" },
			{ number: 13, name: "Zak Gruber", class: "Junior" },
			{ number: 15, name: "Dylan Rubio", class: "Sophomore" },
		],
	},
	// Boys JVB 23
	{
		season: "Boys",
		year: 2023,
		level: "JV-B",
		value: "UXUPNNTH",
		img: jvbboys23,
		coaches: ["Tanner Giessuebel"],
		roster: [
			{ number: 1, name: "Zachary Burgess", class: "Freshman" },
			{ number: 2, name: "Derek Shelton", class: "Freshman" },
			{ number: 3, name: "Sutton Heisey", class: "Freshman" },
			{ number: 5, name: "William Luxbacher", class: "Freshman" },
			{ number: 7, name: "Nathan Adler", class: "Sophomore" },
			{ number: 8, name: "Jayce Rooney", class: "Freshman" },
			{ number: 10, name: "Isak Sanchez", class: "Sophomore" },
			{ number: 11, name: "Samuel Savin", class: "Freshman" },
			{ number: 12, name: "Aiden Messenger", class: "Sophomore" },
			{ number: 13, name: "Elijah Cohen", class: "Freshman" },
			{ number: 15, name: "Thomas Vamos", class: "Freshman" },
		],
	},
	// Boys V 22
	{
		season: "Boys",
		year: 2022,
		level: "Varsity",
		value: "9NSE0UF8",
		img: varsityboys22,
		coaches: ["Andrew Adair", "Kyle Wells"],
		roster: [
			{ number: 1, name: "Johvan Fiallos", class: "Senior" },
			{ number: 4, name: "Gabe Green", class: "Senior" },
			{ number: 5, name: "Tyler DeConcini", class: "Junior" },
			{ number: 6, name: "Connor Fitzgerald", class: "Senior" },
			{ number: 7, name: "Kyle Boesen", class: "Junior" },
			{ number: 8, name: "London Dyjak", class: "Junior" },
			{ number: 9, name: "Logan Freas", class: "Sophomore" },
			{ number: 10, name: "Nick Standridge", class: "Senior" },
			{ number: 11, name: "Cole Revis", class: "Junior" },
			{ number: 12, name: "Jacob Martinez", class: "Freshman" },
		],
		active: true,
	},
	// Boys JV 22
	{
		season: "Boys",
		year: 2022,
		level: "JV",
		img: jvaboys22,
		coaches: ["Tanner Giessuebel"],
		roster: [
			{ number: 1, name: "Miles Cannon", class: "Freshman" },
			{ number: 3, name: "Kyle Senkerik", class: "Freshman" },
			{ number: 4, name: "Josh Eisenberg", class: "Sophomore" },
			{ number: 5, name: "Ethan Greenwood", class: "Freshman" },
			{ number: 6, name: "Tagg Schwabe", class: "Sophomore" },
			{ number: 7, name: "Paul Beltran", class: "Freshman" },
			{ number: 8, name: "Zane Schwartz", class: "Freshman" },
			{ number: 9, name: "Tristan DeClercq", class: "Junior" },
			{ number: 10, name: "Isak Sanchez", class: "Freshman" },
			{ number: 11, name: "Quinten Kiracofe", class: "Freshman" },
			{ number: 12, name: "Wilson Spaulding", class: "Sophomore" },
			{ number: 13, name: "Nathan Adler", class: "Freshman" },
			{ number: 14, name: "Aidan Brewer", class: "Freshman" },
			{ number: 15, name: "Zakary Gruber", class: "Sophomore" },
		],
	},
	// Girls V 24
	{
		season: "Girls",
		year: 2024,
		level: "Varsity",
		img: varsitygirls24,
		coaches: ["Andrew Adair", "Kyle Wells", "Ayda Felix"],
		roster: [
			{ number: 8, name: "Zoe Wung", class: "Senior" },
			{ number: 16, name: "Lucy Rubio", class: "Senior" },
			{ number: 9, name: "Claire Peterson", class: "Senior" },
			{ number: 2, name: "Mercedes Martinez", class: "Senior" },
			{ number: 5, name: "Carly Axen", class: "Senior" },
			{ number: 1, name: "Laila Adcock", class: "Senior" },
			{ number: 10, name: "Alivia Sutton", class: "Junior" },
			{ number: 4, name: "Leah Stoneking", class: "Junior" },
			{ number: 11, name: "Sydney Hellbusch", class: "Junior" },
			{ number: 6, name: "Ale Egginton", class: "Junior" },
			{ number: 7, name: "Rebecca Vazquez", class: "Sophomore" },
			{ number: 3, name: "Zoe Brown", class: "Sophomore" },
		],
		value: "CMSJPY8N",
		active: true,
	},
	// Girls JVA 24
	{
		season: "Girls",
		year: 2024,
		level: "JV-A",
		img: jvagirls24,
		coaches: ["Jules Serna", "Keifer Neerhoff"],
		roster: [
			{ number: 1, name: "Ellie Jurgens", class: "Sophomore" },
			{ number: 2, name: "Suzannah Russell", class: "Freshman" },
			{ number: 3, name: "Madden Bowers", class: "Sophomore" },
			{ number: 4, name: "Olivia Oakes", class: "Sophomore" },
			{ number: 5, name: "Ava Harlow", class: "Sophomore" },
			{ number: 6, name: "Melanie Strunk", class: "Sophomore" },
			{ number: 7, name: "Victoria Perman", class: "Sophomore" },
			{ number: 8, name: "Alex Kaetz", class: "Freshman" },
			{ number: 9, name: "Julia Stingelin", class: "Sophomore" },
			{ number: 10, name: "Anjali Green", class: "Freshman" },
			{ number: 11, name: "Maggie Heslin", class: "Sophomore" },
			{ number: 16, name: "Ellie Peterson", class: "Freshman" },
		],
		value: "MJKCJADS",
		active: true,
	},
	// Girls JVB 24
	{
		season: "Girls",
		year: 2024,
		level: "JV-B",
		img: jvbgirls24,
		coaches: ["Franklin Donley", "Sam Gillman"],
		roster: [
			{ number: 1, name: "Haylie Helton", class: "Freshman" },
			{ number: 2, name: "Aurora Mellor", class: "Freshman" },
			{ number: 3, name: "Bianca Cheung", class: "Freshman" },
			{ number: 4, name: "Kiana Matias", class: "Freshman" },
			{ number: 5, name: "Abigail Peterson", class: "Freshman" },
			{ number: 6, name: "Ada Karaduman", class: "Freshman" },
			{ number: 7, name: "Bianca Valenzuela", class: "Freshman" },
			{ number: 10, name: "Alex Kapke", class: "Freshman" },
			{ number: 11, name: "Joey Waldrup", class: "Freshman" },
			{ number: 12, name: "Olivia Brock", class: "Freshman" },
			{ number: 13, name: "Grace Hairgrove", class: "Freshman" },
			{ number: 14, name: "Lexie Brown", class: "Freshman" },
			{ number: 15, name: "Tenley Bernal", class: "Freshman" },
		],
		value: "E9U19KSW",
		active: true,
	},
	// Girls V 23
	{
		season: "Girls",
		year: 2023,
		level: "Varsity",
		value: "RTL29D0J",
		img: varsitygirls23,
		coaches: ["Andrew Adair"],
		roster: [
			{ number: 1, name: "Laila Adcock", class: "Junior" },
			{ number: 2, name: "Mercy Martinez", class: "Junior" },
			{ number: 3, name: "Lauren Glasner", class: "Senior" },
			{ number: 4, name: "Julia Ford", class: "Senior" },
			{ number: 5, name: "Fiona Rucker", class: "Junior" },
			{ number: 6, name: "Malorie Tarver", class: "Junior" },
			{ number: 7, name: "Cassie Leary", class: "Senior" },
			{ number: 8, name: "Zoe Wung", class: "Junior" },
			{ number: 9, name: "Molly Brunell", class: "Senior" },
			{ number: 10, name: "Tori Staples", class: "Senior" },
			{ number: 11, name: "Sydney Hellbusch", class: "Sophomore" },
			{ number: 16, name: "Lucy Rubio", class: "Junior" },
		],
		active: true,
	},
	// Girls JVA 23
	{
		season: "Girls",
		year: 2023,
		level: "JV-A",
		img: jvagirls23,
		coaches: ["Kyle Wells"],
		roster: [
			{ number: 1, name: "Ava Terpning", class: "Sophomore" },
			{ number: 2, name: "Leah Stoneking", class: "Sophomore" },
			{ number: 3, name: "Jordan DeMont", class: "Sophomore" },
			{ number: 4, name: "Lindon Bourn", class: "Sophomore" },
			{ number: 5, name: "Ava Harlow", class: "Freshman" },
			{ number: 6, name: "Rebecca Vasquez", class: "Freshman" },
			{ number: 7, name: "Ale Eggington", class: "Sophomore" },
			{ number: 8, name: "Claire Peterson", class: "Junior" },
			{ number: 9, name: "Julia Stingelin", class: "Freshman" },
			{ number: 10, name: "Ellie Jurgens", class: "Freshman" },
			{ number: 11, name: "Kira Whitaker", class: "Sophomore" },
			{ number: 13, name: "Madden Bowers", class: "Freshman" },
		],
		value: "D92FBGFI",
	},
	// Girls JVB 23
	{
		season: "Girls",
		year: 2023,
		level: "JV-B",
		img: jvbgirls23,
		coaches: ["Jules Serna"],
		roster: [
			{ number: 1, name: "Sophia Sakali", class: "Freshman" },
			{ number: 2, name: "Presley Jacome", class: "Freshman" },
			{ number: 3, name: "Alexa Mayorquin", class: "Freshman" },
			{ number: 4, name: "Avery Dorf", class: "Freshman" },
			{ number: 5, name: "Sophia Chacon", class: "Freshman" },
			{ number: 6, name: "Melanie Strunk", class: "Freshman" },
			{ number: 7, name: "Soleil Sakali", class: "Freshman" },
			{ number: 9, name: "Zoe Brown", class: "Freshman" },
			{ number: 11, name: "Maggie Heslin", class: "Freshman" },
			{ number: 12, name: "Olivia Oakes", class: "Freshman" },
			{ number: 13, name: "Evelyn Higgins", class: "Freshman" },
			{ number: 14, name: "Sofie Zeh-Plunkett", class: "Freshman" },
		],
		value: "TZS01I1P",
	},
	// Girls V 22
	{
		season: "Girls",
		year: 2022,
		level: "Varsity",
		value: "A7XAR6YJ",
		img: varsitygirls22,
		coaches: ["Andrew Adair", "Carlee Tofel"],
		roster: [
			{ number: 1, name: "Brooklyn Palmer", class: "Freshman" },
			{ number: 2, name: "Laila Adcock", class: "Sophomore" },
			{ number: 3, name: "Bela Mendoza", class: "Junior" },
			{ number: 4, name: "Mercy Martinez", class: "Sophomore" },
			{ number: 5, name: "Ella Cartwright", class: "Senior" },
			{ number: 6, name: "Julia Ford", class: "Junior" },
			{ number: 7, name: "Andrea Soroa", class: "Senior" },
			{ number: 8, name: "Lauren Glasner", class: "Junior" },
			{ number: 9, name: "Molly Brunell", class: "Junior" },
			{ number: 10, name: "Tori Staples", class: "Junior" },
			{ number: 11, name: "Ana Haubner", class: "Senior" },
			{ number: 12, name: "Sydney Hellbusch", class: "Freshman" },
		],
		active: true,
	},
	// Girls JVA 22
	{
		season: "Girls",
		year: 2022,
		level: "JV-A",
		img: jvagirls22,
		coaches: ["Kyle Wells", "Sam Gillman"],
		roster: [
			{ number: 1, name: "Mariana Soroa", class: "Sophomore" },
			{ number: 2, name: "Ava Warner", class: "Sophomore" },
			{ number: 3, name: "Zoe Wung", class: "Sophomore" },
			{ number: 4, name: "Isabella Callesen", class: "Sophomore" },
			{ number: 5, name: "Malorie Tarver", class: "Sophomore" },
			{ number: 6, name: "Jordan DeMont", class: "Freshman" },
			{ number: 8, name: "Ellie Alexander", class: "Junior" },
			{ number: 9, name: "Ingrid Benito", class: "Sophomore" },
			{ number: 10, name: "Carly Axen", class: "Sophomore" },
			{ number: 11, name: "Lindon Bourn", class: "Freshman" },
			{ number: 14, name: "Sam Farbarik", class: "Sophomore" },
			{ number: 16, name: "Lucy Rubio", class: "Sophomore" },
		],
		value: "S55R2L6A",
	},
	// Girls JVB 22
	{
		season: "Girls",
		year: 2022,
		level: "JV-B",
		img: jvbgirls22,
		coaches: ["Karen Villegas", "Jules Serna"],
		roster: [
			{ number: 1, name: "Leah Stoneking", class: "Freshman" },
			{ number: 2, name: "Brynna Bowers", class: "Freshman" },
			{ number: 3, name: "Evy Salyer", class: "Freshman" },
			{ number: 4, name: "Lily Kramkowski", class: "Freshman" },
			{ number: 5, name: "Eva Kolonelos", class: "Freshman" },
			{ number: 6, name: "Ava Terpning", class: "Freshman" },
			{ number: 7, name: "Izzy Ingegneri", class: "Freshman" },
			{ number: 8, name: "Claire Peterson", class: "Sophomore" },
			{ number: 9, name: "Alessandra Egginton", class: "Freshman" },
			{ number: 10, name: "Abigail Hanley", class: "Freshman" },
			{ number: 11, name: "Kira Whitaker", class: "Freshman" },
			{ number: 12, name: "Ariana Madrid", class: "Freshman" },
			{ number: 13, name: "Mia Mendoza", class: "Freshman" },
			{ number: 14, name: "Sophia Dodd", class: "Freshman" },
		],
		value: "UIH6X7NC",
	},
];
