import "./Schedule.css";
import "../App.css";

import { useEffect, useState } from "react";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { TeamList } from "../teams/TeamList";
import TeamSchedule from "./teamSchedule";

function Schedule() {
	const curS = "Boys";
	useEffect(() => {
		handleTeamChange({ target: TeamList.filter((t) => t.season === curS)[0] });
	}, []);

	const seasons = [
		{ label: "Boys", value: "Boys" },
		{ label: "Girls", value: "Girls" },
	];

	const [season, setSeason] = useState(curS);
	const [team, setTeam] = useState(null);
	const [selected, selectGame] = useState(null);

	const handleSeasonChange = (event) => {
		setSeason(event.target.value);
		handleTeamChange({
			target: TeamList.filter((t) => t.season === event.target.value)[0],
		});
	};

	const handleTeamChange = (event) => {
		const teamCode = event.target.value;
		setTeam(null);
		selectGame(null);
		fetch(
			"https://us-central1-volley-stats.cloudfunctions.net/app/teams/" +
				teamCode
		)
			.then((res) => res.json())
			.then(
				(result) => {
					let thisTeam = TeamList.find((t) => t.value === teamCode);
					thisTeam.info = result;
					setTeam(thisTeam);
				},
				(error) => {
					setTeam(TeamList.find((t) => t.value === teamCode));
					console.log("Error");
				}
			);
	};

	return (
		<div>
			<Header active={"Watch"} />
			<div className="content">
				<div className="content-block">
					{team ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Dropdown
								label="Season:"
								options={seasons}
								value={season}
								onChange={handleSeasonChange}
								key={season}
							/>
							<Dropdown
								label="Team:"
								options={TeamList.filter(
									(t) => t.season === season && t.active
								).map((t) => {
									return { value: t.value, label: `${t.year} ${t.level}` };
								})}
								value={`${team.value}`}
								onChange={handleTeamChange}
								key={team.value}
							/>
						</div>
					) : null}
					<TeamSchedule
						team={team}
						selected={selected}
						selectGame={selectGame}
					/>
					<h5 style={{ margin: "4px" }}>
						This page should be up to date, but always double check the{" "}
						<a
							href={
								season === "Boys"
									? "https://azpreps365.com/teams/volleyball-boys/1005-catalina-foothills/161666-varsity"
									: "https://azpreps365.com/teams/volleyball-girls/998-cfoothills/161617-varsity"
							}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "underline" }}
						>
							AIA's official site
						</a>{" "}
						for official dates and match start times.
					</h5>
				</div>
			</div>
			<Footer />
		</div>
	);
}

const Dropdown = ({ label, value, options, onChange }) => {
	console.log(value);
	return (
		<div className="dropdown">
			<label>{label}</label>
			<div className="select">
				<select value={value} onChange={onChange}>
					{options.map((option) => (
						<option value={option.value}>{option.label}</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default Schedule;
