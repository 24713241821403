import { useEffect, useState } from "react";

import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";

const TeamSchedule = ({ team, selected, selectGame }) => {
	let games =
		team && team.info
			? team.info.games
					.filter((game) => game.teamName.substring(0, 3) !== "[p]")
					.sort((a, b) => (a.date > b.date ? 1 : -1))
			: null;
	return team ? (
		<div>
			<hr />
			<div
				style={
					selected && selected.embed
						? {}
						: {
								width: "100vw",
								maxHeight: "60vh",
								aspectRatio: "16/9",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundImage: `url(${team.img})`,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundBlendMode: "luminosity",
								backgroundColor: "var(--main-color)",
								color: "white",
						  }
				}
			>
				{selected ? (
					selected.embed ? (
						<GameView game={selected} />
					) : (
						<div
							style={{ backgroundColor: "var(--main-color)", padding: "2px" }}
						>
							<h4 style={{ filter: "drop-shadow(0 0 3px black)" }}>
								We're not live yet, check back later!
							</h4>
						</div>
					)
				) : (
					<div style={{ backgroundColor: "var(--main-color)", padding: "2px" }}>
						<h4>Select a Match Below to Watch Live!</h4>
					</div>
				)}
			</div>
			<hr />
			{games ? (
				<>
					<h3>Schedule</h3>
					<GameKey />

					<div className="gameButtonContainer">
						{games.map((game, index) => {
							game.tid = team.info.id;
							const date = new Date(game.date);
							return (
								<div>
									{index === 0 ||
									new Date(games[index - 1].date).setHours(0, 0, 0, 0) !=
										date.setHours(0, 0, 0, 0) ? (
										<h6>{date.toLocaleString("en-US", options)}</h6>
									) : null}
									<GameCell
										game={game}
										key={game.id}
										selectGame={selectGame}
										selected={selected}
									/>
								</div>
							);
						})}
					</div>
				</>
			) : (
				<h2>Loading Matches...</h2>
			)}
		</div>
	) : (
		<h2>Loading..</h2>
	);
};

const options = {
	weekday: "long",
	year: "numeric",
	month: "long",
	day: "numeric",
};

const GameKey = () => {
	return (
		<div className="gameKeyContainer">
			<div className="gameKey">
				<div className="keybox home" />
				Home
			</div>
			<div className="gameKey">
				<div className="keybox away" />
				Away
			</div>
			<div className="gameKey">
				<div className="keybox tournament" />
				Tournament
			</div>
		</div>
	);
};

const GameView = ({ game }) => {
	const width = window.innerWidth;
	let split = game.embed
		.replace("?autoplay=1", "")
		.replace("&livemonitor=1", "")
		.split('"');
	split[1] = width > 1280 ? 1280 : width > 640 ? 640 : 320;
	split[3] = width > 1280 ? 720 : width > 640 ? 360 : 180;
	const embedCode = split.toString().replaceAll(",", '"');

	const [attacking, setAttacking] = useState([]);
	const [serving, setServing] = useState([]);
	const [defense, setDefense] = useState([]);
	const [blocking, setBlocking] = useState([]);
	const [assists, setAssists] = useState([]);
	const [reloading, setReloading] = useState(false);

	const getStats = () => {
		setReloading(true);
		fetch(
			"https://us-central1-volley-stats.cloudfunctions.net/app/gamestats/" +
				JSON.stringify({ tid: game.tid, gid: game.id })
		)
			.then((res) => res.json())
			.then(
				(result) => {
					setReloading(false);
					const att = result
						.filter((p) => p.stats && p.stats.attackKill)
						.sort((a, b) => (a.stats.attackKill > b.stats.attackKill ? -1 : 1))
						.splice(0, 3)
						.map((a) => {
							const s = a.stats;
							const err = s.attackErr ? s.attackErr : 0;
							const dug = s.attackDug ? s.attackDug : 0;
							const per = (
								(s.attackKill - err) /
								(s.attackKill + err + dug)
							).toFixed(3);
							return `${a.firstName} ${a.lastName.substring(0, 1)}: ${
								a.stats.attackKill
							} kill${a.stats.attackKill > 1 ? "s" : ""}, ${per}%`;
						});
					setAttacking(att);

					const srv = result
						.filter((p) => p.stats && p.stats.serve0)
						.sort((a, b) => (a.stats.serve0 > b.stats.serve0 ? -1 : 1))
						.splice(0, 3)
						.map(
							(a) =>
								`${a.firstName} ${a.lastName.substring(0, 1)}: ${
									a.stats.serve0
								}`
						);
					setServing(srv);

					const def = result
						.filter((p) => p.stats && p.stats.defDig)
						.sort((a, b) => (a.stats.defDig > b.stats.defDig ? -1 : 1))
						.splice(0, 3)
						.map(
							(a) =>
								`${a.firstName} ${a.lastName.substring(0, 1)}: ${
									a.stats.defDig
								}`
						);
					setDefense(def);

					const blk = result
						.filter((p) => p.stats && p.stats.defBlock)
						.sort((a, b) => (a.stats.defBlock > b.stats.defBlock ? -1 : 1))
						.splice(0, 3)
						.map(
							(a) =>
								`${a.firstName} ${a.lastName.substring(0, 1)}: ${
									a.stats.defBlock
								}`
						);
					setBlocking(blk);

					const set = result
						.filter((p) => p.stats && p.stats.settingAssist)
						.sort((a, b) =>
							a.stats.settingAssist > b.stats.settingAssist ? -1 : 1
						)
						.splice(0, 3)
						.map(
							(a) =>
								`${a.firstName} ${a.lastName.substring(0, 1)}: ${
									a.stats.settingAssist
								}`
						);
					setAssists(set);
				},
				(error) => {
					console.log("Error");
				}
			);
	};

	useEffect(() => {
		getStats();
	}, [game]);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<div dangerouslySetInnerHTML={{ __html: embedCode }}></div>
			<div
				style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
			>
				<div className="statBox">
					<h6>Attacking</h6>
					{attacking.map((attack) => {
						return <p>{attack}</p>;
					})}
				</div>
				<div className="statBox">
					<h6>Aces</h6>
					{serving.map((serve) => {
						return <p>{serve}</p>;
					})}
				</div>
				<div className="statBox">
					<h6>Digs</h6>
					{defense.map((def) => {
						return <p>{def}</p>;
					})}
				</div>
				<div className="statBox">
					<h6>Blocks</h6>
					{blocking.map((blk) => {
						return <p>{blk}</p>;
					})}
				</div>
				<div className="statBox">
					<h6>Assists</h6>
					{assists.map((set) => {
						return <p>{set}</p>;
					})}
				</div>
			</div>
			<button onClick={() => getStats()} disabled={reloading}>
				{reloading ? "Loading.." : "Reload Stats"}
			</button>
		</div>
	);
};

const GameCell = ({ game, selectGame, selected }) => {
	let ourSets = 0;
	let theirSets = 0;
	for (let i = 0; i < 5; i++) {
		const temp = game.ourScores[i] - game.theirScores[i];
		if (temp > 0) {
			ourSets += 1;
		} else if (temp < 0) {
			theirSets += 1;
		}
	}
	return (
		<>
			<button
				className={
					(selected
						? selected.id === game.id
							? "selected gamebutton "
							: "gamebutton "
						: "gamebutton ") +
					(game.type == "Tournament"
						? "tournament"
						: game.location.toLowerCase())
				}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: "smooth" });
					selectGame(game);
				}}
				key={game.id}
			>
				<h5>
					{game.location == "Home"
						? "vs "
						: game.location == "Away"
						? "@ "
						: ""}
					{game.teamName}
				</h5>
			</button>
			{ourSets + theirSets > 0 ? (
				<>
					<span style={{ color: ourSets > theirSets ? "green" : "red" }}>
						{" "}
						{ourSets} - {theirSets}
					</span>
				</>
			) : null}
		</>
	);
};

export default TeamSchedule;
